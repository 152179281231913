class GetPathReportingDetailResult {
    constructor(id, name, source, mappings, schedule) {
        this.Id = id;
        this.Name = name;
        this.Source = source;
        this.Mappings = mappings;
        this.Schedule = schedule;
    }
}

export default GetPathReportingDetailResult;
