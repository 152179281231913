import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import GetDatabaseReferencesResult from "../classes/api/result/datastructuremodule/get-database-references-result.js";
import UpsertDatabaseReferencesResult from "../classes/api/result/datastructuremodule/upsert-database-references-result.js";
import DatabaseReferenceIdAndKeyDto from "../classes/dtos/database-reference-id-and-key-dto.js";
import GetTableReferencesResult from "../classes/api/result/datastructuremodule/get-table-references-result.js";
import UpsertTableReferencesResult from "../classes/api/result/datastructuremodule/upsert-table-references-result.js";
import TableReferenceIdAndKeyDto from "../classes/dtos/table-reference-id-and-key-dto.js";
import GetFieldReferencesResult from "../classes/api/result/datastructuremodule/get-field-references-result.js";
import UpsertFieldReferencesResult from "../classes/api/result/datastructuremodule/upsert-field-references-result.js";
import FieldReferenceIdAndKeyDto from "../classes/dtos/field-reference-id-and-key-dto.js";
import FieldTypeReferenceDto from "../classes/dtos/field-type-reference-dto";

const DataStructureApi = {

    GetDatabaseReferencesAsync: async function (dataSourceId) {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/DataStructure/GetDatabaseReferences?customerId=" + customerId.toString() + "&dataSourceId=" + dataSourceId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetDatabaseReferencesResult(data.lastUpdateDate, data.databases.map((e)=> new DatabaseReferenceIdAndKeyDto(e.id,e.key,e.name))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    GetTableReferencesAsync: async function (databaseReferenceId) {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/DataStructure/GetTableReferences?customerId=" + customerId.toString() + "&databaseReferenceId=" + databaseReferenceId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetTableReferencesResult(data.lastUpdateDate, data.tables.map((e) => new TableReferenceIdAndKeyDto(e.id, e.key, e.name, e.type, e.readOnly, e.informativeRowCount))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    GetFieldReferencesAsync: async function (tableReferenceId) {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/DataStructure/GetFieldReferences?customerId=" + customerId.toString() + "&tableReferenceId=" + tableReferenceId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();

            return ProcessingResult.Ok(new GetFieldReferencesResult(data.lastUpdateDate, data.fields.map((e) => new FieldReferenceIdAndKeyDto(e.id, e.sourceKey, e.datasetKey, e.name, new FieldTypeReferenceDto(e.type.type, e.type.typeLabel, e.type.isNullable, e.type.maxLength, e.type.originTypeLabel, e.type.linkTableId)))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    UpsertDatabaseReferencesAsync: async function (databaseDataSourceId, databases)
    {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url+"/DataStructure/UpsertDatabaseReferences", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId' : customerId, 
                                   'databaseDataSourceId': databaseDataSourceId,
                                   'databases': databases
                                 })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new UpsertDatabaseReferencesResult(data.lastUpdateDate, data.databases.map((e)=> new DatabaseReferenceIdAndKeyDto(e.id,e.key,e.name))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    UpsertTableReferencesAsync: async function (databaseDataSourceId, databaseReferenceId, tables)
    {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url+"/DataStructure/UpsertTableReferences", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId' : customerId, 
                                   'databaseDataSourceId': databaseDataSourceId,
                                   'databaseReferenceId': databaseReferenceId,
                                   'tables': tables
                                 })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new UpsertTableReferencesResult(data.lastUpdateDate, data.tables.map((e) => new TableReferenceIdAndKeyDto(e.id, e.key, e.name, e.type, e.readOnly, e.informativeRowCount))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    UpsertFieldReferencesAsync: async function (databaseDataSourceId, tableReferenceId, fields)
    {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url+"/DataStructure/UpsertFieldReferences", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId' : customerId, 
                                   'databaseDataSourceId': databaseDataSourceId,
                                   'tableReferenceId': tableReferenceId,
                                   'fields': fields
                                 })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new UpsertFieldReferencesResult(data.lastUpdateDate, data.fields.map((e) => new FieldReferenceIdAndKeyDto(e.id, e.sourceKey, e.datasetKey, e.name, new FieldTypeReferenceDto(e.type.type, e.type.typeLabel, e.type.isNullable, e.type.maxLength, e.type.originTypeLabel, e.type.linkTableId)))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    }
};

export default DataStructureApi;