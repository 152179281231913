class GetDatabaseReferencesResult {
    constructor (status, databases) {
        this.Status = status;
        this.Databases = databases;
    }
}

export default GetDatabaseReferencesResult;


