import ProcessingResult from "../../framework/ProcessingResult";

import Delay from "../../tools/delay.js";

import DataSourceApi from "../../apis/datasource-api";

import GetRowsResult from "../../classes/logic/get-rows-result";

import { StatusUnavailable, StatusLive, StatusError, StatusTimeout } from "../../classes/const/job-status";

const RowsLogic = {

    GetRowsAsync: async function (dataSource, tableReference, fieldReferenceIds, maximumRowCount) {

        var createRequestRowsResult = await DataSourceApi.CreateRequestRowsAsync(dataSource.Id, tableReference.Id, fieldReferenceIds, maximumRowCount);

        if (createRequestRowsResult.IsFailed()) {
            return ProcessingResult.Error("Error in CreateRequestRowsAsync " + createRequestRowsResult.ErrorMessage);
        }

        if (createRequestRowsResult.Result.Accepted) {
            var token = createRequestRowsResult.Result.Token;
            for (let i = 0; i < 20; i++) {
                var getRequestRowsResult = await DataSourceApi.GetRequestRowsAsync(token)
                if (getRequestRowsResult.Result.Status === 4) {
                    return ProcessingResult.Ok(new GetRowsResult(StatusLive, getRequestRowsResult.Result.Rows));
                }
                if (getRequestRowsResult.Result.Status === 3) {
                    return ProcessingResult.Ok(new GetRowsResult(StatusError, []));
                }

                await Delay.Sleep(500)
            }
            return ProcessingResult.Ok(new GetRowsResult(StatusTimeout, []));
        } else {
            return ProcessingResult.Ok(new GetRowsResult(StatusUnavailable, []));
        }
    }
};

export default RowsLogic;