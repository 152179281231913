import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import PathTypeListDto from "../classes/dtos/pathmodule/PathTypeListDto";

const PathTypeApi = {

    GetPathTypesAsync : async function ()
    {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url+"/PathType/GetPathTypes?customerId="+customerId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(data.pathTypes.map((e) => new PathTypeListDto(e.id, e.name)));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());

    }
};

export default PathTypeApi;