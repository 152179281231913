import React, {useCallback, useState, useEffect} from 'react';

import Box from './../wrapper/box';

import { Table } from 'reactstrap';

import PathReportingField from './PathReportingField.js';

import FieldReferenceLogic from './../../frontend/businesslayers/datastructuremodule/field-references-logic.js';

function PathReportingFields(props) {

    const [sourceFields, setSourceFields] = useState(null);

    const currentSourceTableChange = useCallback(async()=>
    {
        setSourceFields(null);
    },[]);

    const sourceFieldsLoad = useCallback(async()=>
    {
        if (props.tableReferenceSource != null)
        {
            if (!sourceFields) {

                var getFieldsReferencesResult = await FieldReferenceLogic.GetFieldReferencesAsync(props.dataSourceSource, props.tableReferenceSource, !props.fullLoadingInProgress);

                if (getFieldsReferencesResult.IsFailed())
                {
                    // ERROR
                }
    
                var allFields = getFieldsReferencesResult.Result.Fields;

                setSourceFields(allFields);
            }
        }
    },[props.tableReferenceSource, props.dataSourceSource, props.fullLoadingInProgress, sourceFields]);

    useEffect(() => {
        currentSourceTableChange()
    }, [currentSourceTableChange]);

    useEffect(() => {
        sourceFieldsLoad()
    }, [sourceFieldsLoad]);

    if (props.fieldsSource == null)
    {
        return (<Box width='100%'></Box>);
    }

    return (
        <Box width='100%'>
            {
                <div width='100%'>
                    <Table striped width='100%'>
                        <thead>
                            <tr>
                                <th>Field</th>
                                <th>Exposed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.fieldsSource.map((field, i) => {
                                    return (<PathReportingField index={i} field={field} mapping={props.mapping} setMapping={props.setMapping} sourceFieldReferenceId={field.Id}></PathReportingField>)
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            }
        </Box>
    );
}

export default PathReportingFields;