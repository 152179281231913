import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import GetPathReportingDetailResult from "../classes/api/result/pathreportingmodule/get-pathreporting-detail-result.js";
import UpdatePathPeportingDetailResult from "../classes/api/result/pathreportingmodule/update-pathreporting-detail-result.js";
//import CreateRequestPathSynchroResult from "../classes/api/result/pathsynchromodule/create-request-pathsynchro-result.js";
//import GetRequestPathSynchroResult from "../classes/api/result/pathsynchromodule/get-request-pathsynchro-result.js";
import TableMappingReadDto from "../classes/dtos/syncmodule/table-mapping-read-dto.js";
import FieldMappingReadDto from "../classes/dtos/pathreportingmodule/field-mapping-read-dto.js";
import ScheduleDto from "../classes/dtos/schedulermodule/schedule-dto";

const PathReportingApi = {

    GetPathReportingDetailAsync: async function (pathId) {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/PathReporting/GetPathReportingDetail?customerId=" + customerId.toString() + "&pathId=" + pathId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();

            var source = data.source != null ? new TableMappingReadDto(data.source.dataSourceId,data.source.databaseReferenceId,data.source.tableReferenceId) : null;
            var mappings = data.mappings.map((e) => new FieldMappingReadDto(e.fieldReferenceId, e.isQueryable, e.isRequired, e.defaultValue, e.isExposed));
            var schedule = new ScheduleDto(data.schedule.isActive, data.schedule.scheduleType, data.schedule.interval, data.schedule.time);
            return ProcessingResult.Ok(new GetPathReportingDetailResult(data.id, data.name, source, mappings, schedule));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    UpdatePathReportingDetailAsync : async function (pathId, name, mappings, sourceTableReferenceId, schedule)
    {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url +"/PathReporting/UpdatePathReportingDetail", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId' : customerId, 
                                   'pathId': pathId,
                                   'name': name,
                                   'TableReferenceId': sourceTableReferenceId,
                                   'mappings': mappings.map((e) => { return { FieldReferenceId: e.SourceFieldReferenceId, isQueryable: e.IsQueryable, isRequired: e.IsRequired, defaultValue: e.DefaultValue, isExposed: e.IsExposed } }),
                                   'schedule': {
                                       'isActive': schedule.IsActive,
                                       'scheduleType': schedule.Type,
                                       'interval': schedule.Interval,
                                       'time': schedule.Time
                                   }})
        });

        if (response.status === 200)
        {
            var data = await response.json();
            return ProcessingResult.Ok(new UpdatePathPeportingDetailResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    } /*,

    CreateRequestPathSynchroAsync: async function (pathId) {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/PathSynchro/CreateRequestPathSynchro", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'pathId': pathId
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateRequestPathSynchroResult(data.accepted, data.token));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetRequestPathSynchroAsync: async function (taskId) {
        let authSession = await AuthToken.get();

        // TODO :To add on API side var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/PathSynchro/GetRequestPathSynchro?TaskId=" + taskId, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetRequestPathSynchroResult(data.status, data.progress));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }*/
};

export default PathReportingApi;