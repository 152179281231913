import ProcessingResult from "../../framework/ProcessingResult";

import Delay from "../../tools/delay.js";

import DataSourceApi from "../../apis/datasource-api";
import DataStructureApi from "../../apis/datastructure-api";

import GetFieldReferencesResult from "../../classes/logic/get-field-references-result";

import { StatusUnavailable, StatusLive, StatusError, StatusTimeout, StatusNoData, StatusCache } from "../../classes/const/job-status";

const FieldReferenceLogic = {

    GetFieldReferencesAsync: async function (dataSource, tableReference, withUpdate) {

        if (withUpdate) {
            var createRequestFieldsResult = await DataSourceApi.CreateRequestFieldsAsync(dataSource.Id, tableReference.Id);

            if (createRequestFieldsResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateRequestFieldsAsync " + createRequestFieldsResult.ErrorMessage);
            }
        }

        if (withUpdate && createRequestFieldsResult.Result.Accepted) {
            var token = createRequestFieldsResult.Result.Token;
            for (let i = 0; i < 20; i++) {
                var getRequestFieldsResult = await DataSourceApi.GetRequestFieldsAsync(token)

                if (getRequestFieldsResult.IsFailed()) {
                    return ProcessingResult.Error("Error in GetRequestFieldsAsync " + getRequestFieldsResult.ErrorMessage);
                }

                if (getRequestFieldsResult.Result.Status === 4) {
                    return ProcessingResult.Ok(new GetFieldReferencesResult(StatusLive, getRequestFieldsResult.Result.Fields));
                }

                if (getRequestFieldsResult.Result.Status === 3) {
                    return ProcessingResult.Ok(new GetFieldReferencesResult(StatusError, []));
                }

                await Delay.Sleep(500)
            }
            return ProcessingResult.Ok(new GetFieldReferencesResult(StatusTimeout, []));
        }
        else {
            var getFieldReferencesResult = await DataStructureApi.GetFieldReferencesAsync(tableReference.Id);

            if (getFieldReferencesResult.IsFailed()) {
                return ProcessingResult.Error("Error in GetFieldReferencesAsync " + getFieldReferencesResult.ErrorMessage);
            }

            if (getFieldReferencesResult.Result.LastUpdateDate == null) {
                return ProcessingResult.Ok(new GetFieldReferencesResult(StatusNoData, []));
            }
            else {
                if (!withUpdate) {
                    return ProcessingResult.Ok(new GetFieldReferencesResult(StatusCache, getFieldReferencesResult.Result.Fields));
                } else {
                    return ProcessingResult.Ok(new GetFieldReferencesResult(StatusUnavailable, getFieldReferencesResult.Result.Fields))
                }
            }
        }
    }
};

export default FieldReferenceLogic;