import ProcessingResult from "../../framework/ProcessingResult";

import Delay from "../../tools/delay.js";

import DataSourceApi from "../../apis/datasource-api";
import DataStructureApi from "../../apis/datastructure-api";

import GetTableReferencesResult from "../../classes/logic/get-table-references-result";

import { StatusUnavailable, StatusLive, StatusError, StatusTimeout, StatusNoData, StatusCache } from "../../classes/const/job-status";

const TableReferenceLogic = {

    GetTableReferencesAsync: async function (dataSource, databaseReference, withUpdate) {

        if (withUpdate) {
            var createRequestTablesResult = await DataSourceApi.CreateRequestTablesAsync(dataSource.Id, databaseReference.Id);

            if (createRequestTablesResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateRequestTablesAsync " + createRequestTablesResult.ErrorMessage);
            }
        }

        if (withUpdate && createRequestTablesResult.Result.Accepted) {
            var token = createRequestTablesResult.Result.Token;
            for (let i = 0; i < 20; i++) {
                var getRequestTablesResult = await DataSourceApi.GetRequestTablesAsync(token)

                if (getRequestTablesResult.IsFailed()) {
                    return ProcessingResult.Error("Error in GetRequestTablesAsync " + getRequestTablesResult.ErrorMessage);
                }

                if (getRequestTablesResult.Result.Status === 4) {
                    return ProcessingResult.Ok(new GetTableReferencesResult(StatusLive, getRequestTablesResult.Result.Tables));
                }

                if (getRequestTablesResult.Result.Status === 3) {
                    return ProcessingResult.Ok(new GetTableReferencesResult(StatusError, []));
                }

                await Delay.Sleep(500)
            }
            return ProcessingResult.Ok(new GetTableReferencesResult(StatusTimeout, []));
        }
        else {
            var getTableReferencesResult = await DataStructureApi.GetTableReferencesAsync(databaseReference.Id);

            if (getTableReferencesResult.IsFailed()) {
                return ProcessingResult.Error("Error in GetTableReferencesAsync " + getTableReferencesResult.ErrorMessage);
            }

            if (getTableReferencesResult.Result.LastUpdateDate == null) {
                return ProcessingResult.Ok(new GetTableReferencesResult(StatusNoData, []));
            }
            else {
                if (!withUpdate) {
                    return ProcessingResult.Ok(new GetTableReferencesResult(StatusCache, getTableReferencesResult.Result.Tables));
                } else {
                    return ProcessingResult.Ok(new GetTableReferencesResult(StatusUnavailable, getTableReferencesResult.Result.Tables));
                }
            }
        }
    }
};

export default TableReferenceLogic;