class DataSourceStandardDatabaseDto {
    constructor(id, name, type, connectorType, customerAgentId, connectionString) {
        this.Id = id;
        this.Name = name;
        this.Type = type;
        this.ConnectorType = connectorType;
        this.CustomerAgentId = customerAgentId;
        this.ConnectionString = connectionString;
    }
}

export default DataSourceStandardDatabaseDto;