import React, { useState, useEffect } from "react";

import DataSourceCredentialApi from '../../apis/datasourcecredential-api.js';

//import { Select } from "@airtable/blocks/ui";
import SelectKeyNumber from '../framework/selectkeynumber';
import TextButton from './../../../components/wrapper/textbutton';
import Tooltip from "../framework/tooltip.js";
import Box from './../../../components/wrapper/box';
import Label from './../../../components/wrapper/label';

import ActionConst from "../action-const";

import DataSourceCredentialDialog from './datasourcecredential-dialog.js';

function DataSourceCredentialSelect(props) {

    const [dataSourceCredentials, setDataSourceCredentials] = useState(null);
    const [dataSourceCredentialCreated, setDataSourceCredentialCreated] = useState(null);

    useEffect(() => {

        if (props.dataSourceType == null) {
            return;
        }

        if (!dataSourceCredentials) {
            DataSourceCredentialApi.GetDataSourceCredentialsByCustomerAsync(props.dataSourceType.Id)
                .then(getDataSourceCredentialsResult => {
                    if (getDataSourceCredentialsResult.IsFailed()) {
                        // ERROR
                    }
                    setDataSourceCredentials(getDataSourceCredentialsResult.Result.DataSourceCredentials);
                    if (props.defaultId != null) {
                        var loaded = getDataSourceCredentialsResult.Result.DataSourceCredentials.find((dataSourceCredential) => dataSourceCredential.Id === props.defaultId);
                        if (loaded != null) {
                            props.setDataSourceCredential(loaded);
                        }
                    }
                });
        }
    }, [dataSourceCredentials, props]);

    useEffect(() => {
        setDataSourceCredentials(null);
    }, [props.dataSourceType]);

    const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
    const [updateDialogIsOpen, setUpdateDialogIsOpen] = useState(false);

    const openCreateDialog = () => setCreateDialogIsOpen(true);
    const openUpdateDialog = () => setUpdateDialogIsOpen(true);

    if (dataSourceCredentials == null)
    {
        return (<div>Chargement en cours 🚀</div>);
    }

    var dataSourceCredentialsUi = dataSourceCredentials.map(dataSourceCredential => (
        { value: dataSourceCredential.Id, label : dataSourceCredential.Name }
    ));

    dataSourceCredentialsUi.unshift({ value: -1, label : "Choose a data source credential" });

    var currentDataSourceCredentialId = props.dataSourceCredential != null ? props.dataSourceCredential.Id : -1;

    if (dataSourceCredentialCreated != null) {
        props.setDataSourceCredential(dataSourceCredentials.find((dataSourceCredential) => dataSourceCredential.Id === dataSourceCredentialCreated) ?? null);
        setDataSourceCredentialCreated(null);
    }

    return (
        <Box width='100%'>
            <Box className='nowcode-selectline'>
                {dataSourceCredentials.length === 0 ?
                    <Box width='100%'><Label alignSelf='center' margin={0}>You should create your first account</Label></Box> :
                    <SelectKeyNumber
                        options={dataSourceCredentialsUi}
                        value={currentDataSourceCredentialId}
                        onChange={Id => props.setDataSourceCredential(dataSourceCredentials.find((dataSourceCredential) => dataSourceCredential.Id === Id) ?? null)}
                    />}
                <Tooltip content="Create a datasource credential">
                    <TextButton margin={1} icon="plus" onClick={openCreateDialog} />
                </Tooltip>
                <Tooltip content="Edit a datasource credential">
                    <TextButton margin={1} icon="edit" onClick={openUpdateDialog} disabled={props.dataSourceCredential === null} />
                </Tooltip>
            </Box>
            {createDialogIsOpen ? (<DataSourceCredentialDialog action={ActionConst.Create} setDialogIsOpen={setCreateDialogIsOpen} setDataSourceCredentials={setDataSourceCredentials} setDataSourceCredentialCreated={setDataSourceCredentialCreated} dataSourceType={props.dataSourceType} currentDataSourceAccess={null} />) : ("")}
            {updateDialogIsOpen ? (<DataSourceCredentialDialog action={ActionConst.Update} setDialogIsOpen={setUpdateDialogIsOpen} setDataSourceCredentials={setDataSourceCredentials} setDataSourceCredentialCreated={setDataSourceCredentialCreated} dataSourceType={props.dataSourceType} currentDataSourceAccess={props.dataSourceCredential} />) : ("")} 
        </Box>
    );
}

export default DataSourceCredentialSelect;
