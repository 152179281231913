class DataSourceWithCredentialDto {
    constructor(id, name, type, connectorType, customerAgentId, dataSourceCredentialId) {
        this.Id = id;
        this.Name = name;
        this.Type = type;
        this.ConnectorType = connectorType;
        this.CustomerAgentId = customerAgentId;
        this.DataSourceCredentialId = dataSourceCredentialId;
    }
}

export default DataSourceWithCredentialDto;