﻿import ProcessingResult from "../../framework/ProcessingResult";

import Delay from "../../tools/delay.js";

import DataSourceCredentialApi from "../../apis/datasourcecredential-api";

import GetTestDataSourceCredentialResult from "../../classes/logic/get-testdatasourcecredential-result";

export default class DataSourceCredentialCrudLogic {

    public CurrentDataSourceAccessId: number;

    constructor() {
        this.CurrentDataSourceAccessId = null;
    }

    CreateOrUpdateDataSourceAccessAsync = async (name: string, dataSourceType: number, parameters: object) => {

        if (this.CurrentDataSourceAccessId === null) {
            var createDataSourceAccessResult = await DataSourceCredentialApi.CreateDataSourceAccessAsync(name, dataSourceType, parameters);
            if (createDataSourceAccessResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateDataSourceAccessAsync " + createDataSourceAccessResult.ErrorMessage);
            }
            this.CurrentDataSourceAccessId = createDataSourceAccessResult.Result.Id;
        } else {
            var updateDataSourceAccessResult = await DataSourceCredentialApi.UpdateDataSourceAccessAsync(this.CurrentDataSourceAccessId, name, parameters);
            if (updateDataSourceAccessResult.IsFailed()) {
                return ProcessingResult.Error("Error in UpdateDataSourceAccessAsync " + updateDataSourceAccessResult.ErrorMessage);
            }
        }

        var createRequestResult = await DataSourceCredentialApi.CreateRequestTestDataSourceCredentialAsync(this.CurrentDataSourceAccessId);

        if (createRequestResult.IsFailed()) {
            return ProcessingResult.Error("Error in CreateRequestTestDataSourceCredentialAsync " + createRequestResult.ErrorMessage);
        }

        if (createRequestResult.Result.Accepted) {
            var taskId = createRequestResult.Result.Token;
            for (let i = 0; i < 20; i++) {

                var getRequestTestDataSourceCredentialResult = await DataSourceCredentialApi.GetRequestTestDataSourceCredentialAsync(taskId)

                if (getRequestTestDataSourceCredentialResult.Result.Status === 4) {
                    return ProcessingResult.Ok(new GetTestDataSourceCredentialResult(getRequestTestDataSourceCredentialResult.Result.Ok, getRequestTestDataSourceCredentialResult.Result.Error));
                }

                await Delay.Sleep(500)
            }

            return ProcessingResult.Error("Request timeout !");
        }
        else {
            return ProcessingResult.Error("Request not accepted !");
        }
    }

    CreateOrUpdateAirtablePersonalAccessTokenAsync = async (name:string, token: string) => {

        if (this.CurrentDataSourceAccessId === null) {
            var createAirtablePersonalAccessTokenResult = await DataSourceCredentialApi.CreateAirtablePersonalAccessTokenAsync(name, token);
            if (createAirtablePersonalAccessTokenResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateAirtablePersonalAccessTokenAsync " + createAirtablePersonalAccessTokenResult.ErrorMessage);
            }
            this.CurrentDataSourceAccessId = createAirtablePersonalAccessTokenResult.Result.Id;
        } else {
            var updateAirtablePersonalAccessTokenResult = await DataSourceCredentialApi.UpdateAirtablePersonalAccessTokenAsync(this.CurrentDataSourceAccessId, name, token);
            if (updateAirtablePersonalAccessTokenResult.IsFailed()) {
                return ProcessingResult.Error("Error in UpdateAirtablePersonalAccessTokenAsync " + updateAirtablePersonalAccessTokenResult.ErrorMessage);
            }
        }

        var createRequestResult = await DataSourceCredentialApi.CreateRequestTestDataSourceCredentialAsync(this.CurrentDataSourceAccessId);

        if (createRequestResult.IsFailed()) {
            return ProcessingResult.Error("Error in CreateRequestTestDataSourceCredentialAsync " + createRequestResult.ErrorMessage);
        }

        if (createRequestResult.Result.Accepted) {
            var taskId = createRequestResult.Result.Token;
            for (let i = 0; i < 20; i++) {

                var getRequestTestDataSourceCredentialResult = await DataSourceCredentialApi.GetRequestTestDataSourceCredentialAsync(taskId)

                if (getRequestTestDataSourceCredentialResult.Result.Status === 4) {
                    return ProcessingResult.Ok(new GetTestDataSourceCredentialResult(getRequestTestDataSourceCredentialResult.Result.Ok, getRequestTestDataSourceCredentialResult.Result.Error));
                }

                await Delay.Sleep(500)
            }

            return ProcessingResult.Error("Request timeout !");
        }
        else {
            return ProcessingResult.Error("Request not accepted !");
        }
    }

    CreateOrUpdateTimetonicSessionKeyAsync = async (name: string, token: string, oAuthUserId: string, requestUserId: string) => {

        if (this.CurrentDataSourceAccessId === null) {
            var createTimetonicSessionKeyResult = await DataSourceCredentialApi.CreateTimetonicSessionKeyAsync(name, token, oAuthUserId, requestUserId);
            if (createTimetonicSessionKeyResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateTimetonicSessionKeyAsync " + createTimetonicSessionKeyResult.ErrorMessage);
            }
            this.CurrentDataSourceAccessId = createTimetonicSessionKeyResult.Result.Id;
        } else {
            var updateTimetonicSessionKeyResult = await DataSourceCredentialApi.UpdateTimetonicSessionKeyAsync(this.CurrentDataSourceAccessId, name, token, oAuthUserId, requestUserId);
            if (updateTimetonicSessionKeyResult.IsFailed()) {
                return ProcessingResult.Error("Error in UpdateTimetonicSessionKeyAsync " + updateTimetonicSessionKeyResult.ErrorMessage);
            }
        }

        var createRequestResult = await DataSourceCredentialApi.CreateRequestTestDataSourceCredentialAsync(this.CurrentDataSourceAccessId);

        if (createRequestResult.IsFailed()) {
            return ProcessingResult.Error("Error in CreateRequestTestDataSourceCredentialAsync " + createRequestResult.ErrorMessage);
        }

        if (createRequestResult.Result.Accepted) {
            var taskId = createRequestResult.Result.Token;
            for (let i = 0; i < 20; i++) {

                var getRequestTestDataSourceCredentialResult = await DataSourceCredentialApi.GetRequestTestDataSourceCredentialAsync(taskId)

                if (getRequestTestDataSourceCredentialResult.Result.Status === 4) {
                    return ProcessingResult.Ok(new GetTestDataSourceCredentialResult(getRequestTestDataSourceCredentialResult.Result.Ok, getRequestTestDataSourceCredentialResult.Result.Error));
                }

                await Delay.Sleep(500)
            }

            return ProcessingResult.Error("Request timeout !");
        }
        else {
            return ProcessingResult.Error("Request not accepted !");
        }
    }
}