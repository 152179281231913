import React, { useState, useEffect } from "react";

import ScheduleTypeApi from '../../apis/schedule-type-api.js';

import SelectKeyNumber from '../framework/selectkeynumber';

function ScheduleTypeSelect(props) {

    const [scheduleTypes, setScheduleTypes] = useState(null);

    useEffect(() => {
        if (!scheduleTypes) {
            ScheduleTypeApi.GetScheduleTypesAsync()
                .then(allScheduleTypes => {
                    setScheduleTypes(allScheduleTypes.Result);
                });
        }
    }, [scheduleTypes, props.scheduleType]);

    if (scheduleTypes === null) {
        return (<div>Loading</div>);
    }

    if (scheduleTypes.length === 0) {
        return (<div>NO DATA</div>);
    }

    var scheduleTypesUi = scheduleTypes.map(scheduleType => (
        { value: scheduleType.Id, label : scheduleType.Name }
    ));

    scheduleTypesUi.unshift({ value: 0, label : "Choose a schedule type" });

    const changeCurrentScheduleType = (Id) => {
        props.setScheduleType(Id);
    }

    return (
        <SelectKeyNumber
            options={scheduleTypesUi}
            value={props.scheduleType}
            onChange={newValue => changeCurrentScheduleType(newValue) }
        />);
}

export default ScheduleTypeSelect;
