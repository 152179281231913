import React, { useEffect, useState } from "react";

//import { Button, FormField, Input } from "@airtable/blocks/ui";
import FormField from './../../../components/wrapper/formfield';
import Input from './../../../components/wrapper/input';
import Button from './../../../components/wrapper/button';

import Dialog from '../framework/dialog.js';
import DialogBody from "../framework/dialog-body.js";
import DialogFooter from "../framework/dialog-footer.js";

import PathApi from '../../apis/PathApi.js';
import PathTypeApi from '../../apis/path-type-api.js';
import PathTypeSelect from "./path-type-select.js";

import '../../styles/nowcode-toolboxline.css';

const PathDialog = (props) => {

  const [pathTypes, setPathTypes] = useState(null);

  const [pathName, setPathName] = useState("");
  const [pathType, setPathType] = useState(null);

  const isValid = function() {
    return pathName != null && pathName.length > 0 && pathType != null && pathType.Id > 0;
  }

    const createPath = function () {
        PathApi.CreatePathAsync(pathType.Id, pathName).then((createResult) => {
            props.closeDialog(createResult.Result.Id, pathType.Id);
      });
  };

  const cancel = function() {
      props.closeDialog(0, 0);
  }

  useEffect(() => {
      if (!pathTypes) {
          PathTypeApi.GetPathTypesAsync()
              .then(allPathTypes => {
                  setPathTypes(allPathTypes.Result);
              });
      }
  }, [pathTypes]);

  if (!pathTypes) return <div></div>;

  return (
    <React.Fragment>
      {(
        <Dialog onClose={() => cancel()} title="Create a path" width="320px">
          <DialogBody>
            <FormField label="Path name">
              <Input value={pathName} onChange={e => setPathName(e.target.value)} />
            </FormField>
            <FormField label="Path type">
              <PathTypeSelect pathTypes={pathTypes} pathType={pathType} setPathType={setPathType}/>
            </FormField>
          </DialogBody>
          <DialogFooter>
          <div className="nowcode-dialog-footer">
              <Button marginRight={1} onClick={createPath} disabled={!isValid()}>Create</Button>
              <Button onClick={cancel}>Cancel</Button>
            </div>
          </DialogFooter>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default PathDialog;