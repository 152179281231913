import React, { useCallback, useEffect, useState } from 'react';

//import { Label, Box } from '@airtable/blocks/ui';
import Box from './../../../components/wrapper/box';

import InfoBlock from '../framework/info-block';

import DatabaseReferenceLogic from '../../businesslayers/datastructuremodule/database-references-logic.js';

import DatabaseSelect from './database-select.js';

import Table from './table.js';

import '../../styles/nowcode-toolboxline.css';

import { GetMessage } from '../../../frontend/classes/const/job-status';

function Database(props) {
    const [databases, setDatabases] = useState(null);

    const currentDataSourceChange = useCallback(async () => {
        props.setCurrentDatabaseReference(null);
        setDatabases(null);
    }, [props.currentDataSource]);

    const databasesLoad = useCallback(async () => {
        if (props.currentDataSource == null) {
            return;
        }

        if (!databases) {

            var getDatabaseReferencesResult = await DatabaseReferenceLogic.GetDatabaseReferencesAsync(props.currentDataSource, !props.fullLoadingInProgress);

            if (getDatabaseReferencesResult.IsFailed()) {
                alert("ERREUR : " + getDatabaseReferencesResult.ErrorMessage);
            }

            getDatabaseReferencesResult.Result.Databases = getDatabaseReferencesResult.Result.Databases
                .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);

            setDatabases(getDatabaseReferencesResult.Result);

            if (getDatabaseReferencesResult.Result.Status !== "ERROR") {
                var currentDatabaseId = props.tableReferenceLoaded?.DatabaseReferenceId ?? -1;
                var database = getDatabaseReferencesResult.Result.Databases.find((x) => x.Id === currentDatabaseId);
                if (database != null) {
                    props.setCurrentDatabaseReference(database);
                }
            }
        }
    }, [databases]);

    useEffect(() => {
        currentDataSourceChange()
    }, [currentDataSourceChange]);

    useEffect(() => {
        databasesLoad()
    }, [databasesLoad]);

    let message = GetMessage(databases, databases?.Status);
    var withMessage = message !== null;
    var withList = databases !== null && databases.Databases !== null;

    return (
        <div>
            <Box className='nowcode-toolboxline'>
                {withMessage ?
                    (
                        <InfoBlock message={message} />
                    ) :
                    (withList && <DatabaseSelect
                        databases={databases.Databases}
                        currentDatabase={props.currentDatabaseReference}
                        setCurrentDatabase={props.setCurrentDatabaseReference} />)
                }
            </Box>
            {
                props.currentDatabaseReference != null && props.currentDatabaseReference.Id > 0 &&
                <Table
                    currentDataSource={props.currentDataSource}
                    currentDatabase={props.currentDatabaseReference}
                    currentTableReference={props.currentTableReference}
                    setCurrentTableReference={props.setCurrentTableReference}
                    tableReferenceLoaded={props.tableReferenceLoaded}
                    mapping={props.mapping}
                    fullLoadingInProgress={props.fullLoadingInProgress}
                />
            }
        </div>
    );
}

export default Database;
