class CreateOrUpdateDataSourceAccessResult {

    Id: string;

    constructor(id) {
        this.Id = id;
    }
}

export default CreateOrUpdateDataSourceAccessResult;
