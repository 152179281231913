class FieldTypeReferenceDto {
    constructor(type, typeLabel, isNullable, maxLength, originTypeLabel, linkTableId) {
        this.Type = type;
        this.TypeLabel = typeLabel;
        this.IsNullable = isNullable;
        this.MaxLength = maxLength;
        this.OriginTypeLabel = originTypeLabel;
        this.LinkTableId = linkTableId;
    }
}

export default FieldTypeReferenceDto;