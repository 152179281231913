import React, { useCallback, useEffect, useState } from 'react';

//import { Label, Box } from '@airtable/blocks/ui';
import Box from './../../../components/wrapper/box';

import InfoBlock from '../framework/info-block';

import TableReferenceLogic from '../../businesslayers/datastructuremodule/table-references-logic.js';

import TableSelect from './table-select.js';

import '../../styles/nowcode-toolboxline.css';

import { GetMessage } from '../../../frontend/classes/const/job-status';

function Table(props) {
    const [tables, setTables] = useState(null);

    const currentDatabaseChange = useCallback(async () => {
        props.setCurrentTableReference(null);
        setTables(null);
    }, [props.currentDatabase]);

    const tablesLoad = useCallback(async () => {
        if (props.currentDatabase == null) {
            return;
        }

        if (!tables) {
            var getTableReferencesResult = await TableReferenceLogic.GetTableReferencesAsync(props.currentDataSource, props.currentDatabase, !props.fullLoadingInProgress);

            if (getTableReferencesResult.IsFailed()) {
                alert("ERREUR : " + getTableReferencesResult.ErrorMessage);
            }

            getTableReferencesResult.Result.Tables = getTableReferencesResult.Result.Tables
                .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);

            setTables(getTableReferencesResult.Result);

            if (getTableReferencesResult.Result.Status !== "ERROR") {
                var currentTableId = props.tableReferenceLoaded?.TableReferenceId ?? -1;
                var table = getTableReferencesResult.Result.Tables.find((x) => x.Id === currentTableId);
                if (table != null) {
                    props.setCurrentTableReference(table);
                }
            }
        }
    }, [tables]);

    useEffect(() => {
        currentDatabaseChange()
    }, [currentDatabaseChange]);

    useEffect(() => {
        tablesLoad()
    }, [tablesLoad]);

    let message = GetMessage(tables, tables?.Status);
    var withMessage = message !== null;
    var withList = tables !== null && tables.Tables !== null;

    return (
        <div>
            <Box className='nowcode-toolboxline'>
                {withMessage ?
                    (
                        <InfoBlock message={message} />
                    ) :
                    (withList && <TableSelect
                        tables={tables.Tables}
                        currentTableReference={props.currentTableReference}
                        setCurrentTableReference={props.setCurrentTableReference} />)
                }
            </Box>
        </div>
    );
}

export default Table;
