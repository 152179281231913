class GetFieldReferencesResult {
    constructor (status, fields) {
        this.Status = status;
        this.Fields = fields;
    }
}

export default GetFieldReferencesResult;


