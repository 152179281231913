class GetTableReferencesResult {
    constructor (status, tables) {
        this.Status = status;
        this.Tables = tables;
    }
}

export default GetTableReferencesResult;


