class FieldReferenceIdAndKeyDto {
    constructor(id, sourceKey, datasetKey, name, type) {
        this.Id = id;
        this.SourceKey = sourceKey;
        this.DatasetKey = datasetKey;
        this.Name = name;
        this.Type = type;
    }

    get Label() {
        return this.Name + " (" + (this.Type?.OriginTypeLabel ?? "???") + ")";
    }
}

export default FieldReferenceIdAndKeyDto;