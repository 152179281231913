class TableReferenceIdAndKeyDto {
    constructor(id, key, name, type, readOnly, informativeRowCount) {
        this.Id = id;
        this.Key = key;
        this.Name = name;
        this.Type = type;
        this.ReadOnly = readOnly;
        this.InformativeRowCount = informativeRowCount;
    }

    getLabel() {

        let typeLabel = "??";
        if (this.Type === 1) { typeLabel = "Table"; }
        if (this.Type === 2) { typeLabel = "View"; }

        return this.Name + " [" + typeLabel + "]" +
            ((this.InformativeRowCount != null) ? 
                " ("+this.InformativeRowCount+" rows)" : 
                "");
    }
}

export default TableReferenceIdAndKeyDto;