import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import ScheduleTypeListDto from "../classes/dtos/schedulermodule/scheduletype-list-dto";

const ScheduleTypeApi = {

    GetScheduleTypesAsync : async function ()
    {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url+"/ScheduleType/GetScheduleTypes?customerId="+customerId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(data.scheduleTypes.map((e) => new ScheduleTypeListDto(e.id, e.name)));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());

    }
};

export default ScheduleTypeApi;