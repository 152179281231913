export const StatusNoData = "NO_DATA";
export const StatusUnavailable = "UNAVAILABLE";
export const StatusLive = "LIVE";
export const StatusCache = "CACHE";
export const StatusError = "ERROR";
export const StatusTimeout = "TIMEOUT";

export function GetMessage(data, status) {
    let message = null;
    if (data === null) {
        message = "Chargement en cours 🚀";
    } else {
        if (status === StatusError) {
            message = "Error in datasource";
        }

        if (status === StatusUnavailable) {
            message = "Datasource offline";
        }
    }
    return message;
}