//import {base} from '@airtable/blocks';

import ProcessingResult from "../../framework/ProcessingResult";

import Delay from "../../tools/delay.js";

import PathSynchroApi from "../../apis/pathsynchro-api";

const PathSynchroLogic = {

    PathSynchroAsync: async function (pathId, progress) {

        const Timeout = 300 * 1000;

        var date = new Date();
        let timeout = date.getTime() + Timeout;

        var createRequestPathSynchroResult = await PathSynchroApi.CreateRequestPathSynchroAsync(pathId);

        if (createRequestPathSynchroResult.IsFailed()) {
            return ProcessingResult.Error("Error in CreateRequestPathSynchroAsync " + createRequestPathSynchroResult.ErrorMessage);
        }

        if (createRequestPathSynchroResult.Result.Accepted) {
            var token = createRequestPathSynchroResult.Result.Token;
            do {
                var getRequestPathSynchroResult = await PathSynchroApi.GetRequestPathSynchroAsync(token)

                if (getRequestPathSynchroResult.IsFailed()) {
                    return ProcessingResult.Error("Error in GetRequestPathSynchroAsync " + getRequestPathSynchroResult.ErrorMessage);
                }

                progress(getRequestPathSynchroResult.Result.Progress);

                if (getRequestPathSynchroResult.Result.Status === 4) {
                    return ProcessingResult.Ok(getRequestPathSynchroResult.Result.ErrorCode);
                }

                if (getRequestPathSynchroResult.Result.Status === 3) {
                    return ProcessingResult.Ok(getRequestPathSynchroResult.Result.ErrorCode);
                }

                await Delay.Sleep(500)
                date = new Date();
            }
            while (timeout > date.getTime())
            return ProcessingResult.Ok("TIMEOUT");
        }
        else {
            return ProcessingResult.OK("OFFLINE");
        }
    }
};

export default PathSynchroLogic;