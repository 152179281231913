class FieldMappingReadDto {
    constructor (sourceFieldReferenceId,isQueryable, isRequired, defaultValue, isExposed) {
        this.SourceFieldReferenceId = sourceFieldReferenceId;
        this.IsQueryable = isQueryable;
        this.IsRequired = isRequired;
        this.DefaultValue = defaultValue;
        this.IsExposed = isExposed; 
    }
}

export default FieldMappingReadDto;